<template>
   <section class="cont AccountTable">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>对账报表</el-breadcrumb-item>
            <el-breadcrumb-item>销售概况</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>

      <el-row class="content-box">
        <div v-if="selectshow">
          <h3  style="padding: 20px 0">集团销售概况</h3>
          <el-row style="display: flex;">
            <ul class="head-tag-box head-bule">
              <li>销售额</li>
              <li>{{groupsale.totalMoney}}</li>
            </ul>
            <ul class="head-tag-box head-gree">
              <li>房费销售额</li>
              <li>{{groupsale.hotelMoney}}</li>
            </ul>
            <ul class="head-tag-box head-red">
              <li>其他销售额</li>
              <li>{{groupsale.otherMoney}}</li>
            </ul>
          </el-row>
          <h3  style="padding: 20px 0">酒店销售概况</h3>
          <HotelSelect :storeIdQ="storeId" v-if="selectshow" @setstoreId="getDate" style="padding:20px 0 "> </HotelSelect>
          <el-row style="display: flex;">
            <ul class="head-tag-box head-bule">
              <li>销售额</li>
              <li>{{hotelsale.totalMoney}}</li>
            </ul>
            <ul class="head-tag-box head-gree">
              <li>房费销售额</li>
              <li>{{hotelsale.hotelMoney}}</li>
            </ul>
            <ul class="head-tag-box head-red">
              <li>其他销售额</li>
              <li>{{hotelsale.otherMoney}}</li>
            </ul>
          </el-row>
        </div>
        <div v-else>
          <h3  style="padding: 20px 0">酒店销售概况</h3>
          <HotelSelect :storeIdQ="storeId" v-if="selectshow" v-model="storeId"  @setstoreId="getDate" style="padding:20px 0 "> </HotelSelect>
          <el-row style="display: flex;">
            <ul class="head-tag-box head-bule">
              <li>销售额</li>
              <li>{{hotelsale.totalMoney}}</li>
            </ul>
            <ul class="head-tag-box head-gree">
              <li>房费销售额</li>
              <li>{{hotelsale.hotelMoney}}</li>
            </ul>
            <ul class="head-tag-box head-red">
              <li>其他销售额</li>
              <li>{{hotelsale.otherMoney}}</li>
            </ul>
          </el-row>
        </div>


         <!-- 查询框 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class='search-item'>
                  <el-radio-group v-model="checkboxGroup" @change="radioChange" class="group-box">
                     <el-radio-button label="today" :class="checkboxGroup == 'today' ? 'bg-gradient' : ''">本日</el-radio-button>
                     <el-radio-button label="yesterday" :class="checkboxGroup == 'yesterday' ? 'bg-gradient' : ''">昨日</el-radio-button>
                     <el-radio-button label="week" :class="checkboxGroup == 'week' ? 'bg-gradient' : ''">本周</el-radio-button>
                     <el-radio-button label="month" :class="checkboxGroup == 'month' ? 'bg-gradient' : ''">本月</el-radio-button>
                     <el-radio-button label="customDate" :class="checkboxGroup == 'customDate' ? 'bg-gradient' : ''">自定义</el-radio-button>
                  </el-radio-group>
               </el-row>
               <el-row class='search-item'>
                  <label>营业时间：</label>
                     <el-date-picker
                           :disabled="checkboxGroup == 'customDate' ? false : true"
                            v-model="pickerTime" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions" @change="handleTime">
                     </el-date-picker>
               </el-row>
               <el-button :class="checkboxGroup != 'customDate' ? 'disabled-name' : 'bg-gradient'" @click="queryEvent" :disabled="checkboxGroup == 'customDate' ? false : true">搜索</el-button>
               <el-button :class="checkboxGroup != 'customDate' ? 'disabled-name' : 'bg-gradient'" @click="resetEvent">重置</el-button>
               <el-button class="bg-gradient" @click="exportEvent">导出</el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row  style='padding: 40px 0px;'>
            <el-row id="Discount_chart"  style="width:92%;height:500px;margin-top: 1rem; overflow: hidden;"></el-row>
         </el-row>
      </el-row>
   </section>
</template>
<script>
import { mapState } from "vuex";
import { urlObj, business } from '@/api/interface'
import { reconciliation } from "@/api/interface/business";
import { dateFactory, exportFile } from '@/common/js/common'
export default{
   data(){
      return{

         checkboxGroup: "today",
         pickerTime:[],     //营业时间
         selectshow:false,
         groupsale:{
           totalMoney:0,      //销售额
           hotelMoney:0,      //房费销售额
           otherMoney:0,      //其他销售额
         },
         hotelsale:{
           totalMoney:0,      //销售额
           hotelMoney:0,      //房费销售额
           otherMoney:0,      //其他销售额
         },
         storeId:'',
         pickerOptions: {
            disabledDate(time) {
               return time.getTime() > Date.now()-1 * 24 * 3600 * 1000;
            }
         },
         cha_totalMoney:[], //echarts销售额
         cha_hotelMoney:[], //echarts房费销售额
         cha_otherMoney:[], //echarts其他销售额
         timeData:[],       //echarts时间
         today:"",          //获取本日日期
         getInSeconds:'',  //定时器秒
         clearInter:'',    //清除定时器
         clearTimeout:'',  //清除定时器

         getWeekList:'', //获取本周七天日期
      }
   },
   computed: {
      ...mapState(["hotelInfo"])
   },
  mounted() {
      //设置今日日 营业时间
    this.storeId = this.hotelInfo.id

    this.today = dateFactory.getDistanceToday(0,false)
      this.getWeekList = dateFactory.getWeekList()
      this.pickerTime = [this.today,this.today]
      this.chartEvent()
     if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
       this.selectshow = true
       this.getgroupAccount()
       this.getholtelAccount()
     }

      let minutes = new Date().getMinutes()
      let seconds = new Date().getSeconds()
      this.getInSeconds = ((60-minutes)*60*1000)-(seconds*1000)+1
      this.setTimeout()
   },
   beforeDestroy() {
      clearTimeout(this.clearTimeout)
      this.clearTimeout = null
      clearInterval(this.clearInter)
      this.clearInter = null
   },
   methods:{
      //定数器实现每整点刷新一次
      setTimeout(){
        this.clearTimeout = window.setTimeout(() => {
           this.getholtelAccount()
            this.chartEvent()
            this.setTime()
         },this.getInSeconds)
      },
     getDate(val){
       this.storeId = val
       this.getholtelAccount()
     },
      setTime(){
         this.clearInter = window.setInterval(() => {
            this.getholtelAccount()
            this.chartEvent()
         }, 60*60*1000)
      },
     //获取销售额数据
     getholtelAccount(){
       const url = reconciliation.getAmount
       let param = {
         beginTime:this.pickerTime[0],
         endTime:this.pickerTime[1],
         hotelId:this.storeId,
         storeId:this.hotelInfo.storeId
       }

       this.$axios.post(url, param, 'json').then(res => {
         if (res.success) {
           if(res.hasOwnProperty("records")){
             this.hotelsale.totalMoney = res.records.totalMoney
             this.hotelsale.hotelMoney = res.records.hotelMoney
             this.hotelsale.otherMoney = res.records.otherMoney
           }else{
             this.hotelsale.totalMoney = 0
             this.hotelsale.hotelMoney = 0
             this.hotelsale.otherMoney = 0
           }
         }
       })
     },
      //获取销售额数据
      getgroupAccount(){
         const url = reconciliation.getAmount
        let param = {

        }
        if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
          param.storeId =this.hotelInfo.storeId
        }
        this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               if(res.hasOwnProperty("records")){
                  this.groupsale.totalMoney = res.records.totalMoney
                  this.groupsale.hotelMoney = res.records.hotelMoney
                  this.groupsale.otherMoney = res.records.otherMoney
               }else{
                 this.groupsale.totalMoney = 0
                 this.groupsale.hotelMoney = 0
                 this.groupsale.otherMoney = 0
               }
            }
         })
      },
      // 单选框事件
      radioChange(){
         switch (this.checkboxGroup){
            case "today":
               this.pickerTime = [this.today,this.today]
               break;
            case "yesterday":
               this.pickerTime = [dateFactory.getDistanceToday(-1,false), dateFactory.getDistanceToday(-1,false)]
               break;
            case "week":
               this.pickerTime = [this.getWeekList[0],this.getWeekList[6]]
               break;
            case "month":
               this.pickerTime = this.getMonth()
               break;
            case "customDate":
               break;
         }
         // 如果为本日，启动定时器，否则清除定时器
         if(this.checkboxGroup === 'today'){
            this.setTimeout()
         }else{
            clearTimeout(this.clearTimeout)
            this.clearTimeout = null
            clearInterval(this.clearInter)
            this.clearInter = null
         }
         if(this.checkboxGroup != 'customDate'){
            this.getholtelAccount()
            this.chartEvent()
         }
      },
      //获取/设置echarts数据
      chartEvent(){
         let chartDom = document.getElementById('Discount_chart');
         let myChart = this.$echarts.init(chartDom);
         let Discount_chart;
         const url = reconciliation.getAmountDetail
         let param = {
            hotelId:this.storeId,
         }
         //本日昨日 date参数
         if(this.checkboxGroup === "today" || this.checkboxGroup === "yesterday"){
            param.date = this.pickerTime[0]
            param.oneDay = true
         }else{
            param.beginTime = this.pickerTime[0]
            param.endTime = this.pickerTime[1]
         }
         this.$axios.get(url,param).then(res => {
            if (res.success) {
               let chartData = res.records
               this.cha_totalMoney = []
               this.cha_hotelMoney = []
               this.cha_otherMoney = []
               this.timeData = []
               let hoursNum = '' //设置echarts X轴时间段

               switch(this.checkboxGroup){
                  case "today":
                     hoursNum = dateFactory.getDistanceToday(0,true).substring(11,13)
                  break;
                  case "yesterday":
                     hoursNum = 23
                     break;
                  case "week":
                     this.timeData = this.getWeekList
                     hoursNum = 7
                     break;
                  case "month":
                     hoursNum = Number(this.today.substring(8,11))
                     break;
                  case "customDate":
                     this.timeData = dateFactory.getDateRange(this.pickerTime[0],this.pickerTime[1])
                     hoursNum = this.timeData.length
                     break;
               }
               for(let i = 0; i <= hoursNum ; i++ ){
                  if(this.checkboxGroup === 'today' || this.checkboxGroup === 'yesterday') this.timeData.push( i +'时')
                  if(this.checkboxGroup === "month") this.timeData.push( i <10 ? "0"+i+ "日" : i +'日' )
                  this.cha_totalMoney.push(0)
                  this.cha_hotelMoney.push(0)
                  this.cha_otherMoney.push(0)
               }
               chartData.forEach( item => {
                  //判断获取对应下标
                  if(this.checkboxGroup === "today" || this.checkboxGroup === "yesterday"){
                     if(item.date){
                        if(item.date.indexOf('0') == 0) item.dayIndex = item.date.substring(1,2)
                        else item.dayIndex = item.date
                     }
                  }else if(this.checkboxGroup === "week" || this.checkboxGroup === "customDate"){
                     this.timeData.forEach((items,indexs)=>{
                        if(items == item.date) item.dayIndex = indexs
                     })
                  }else if(this.checkboxGroup === "month"){
                     this.timeData.forEach((items,indexs)=>{
                        if(items.substring(0,2) === item.date.substring(8,10)) item.dayIndex = indexs
                     })
                  }
                  if(item.totalMoney && Number(item.dayIndex)<=Number(hoursNum)) this.cha_totalMoney[item.dayIndex] = item.totalMoney
                  if(item.hotelMoney && Number(item.dayIndex)<=Number(hoursNum)) this.cha_hotelMoney[item.dayIndex] = item.hotelMoney
                  if(item.otherMoney && Number(item.dayIndex)<=Number(hoursNum)) this.cha_otherMoney[item.dayIndex] = item.otherMoney
               })
               //总销售额  房费销售额 非房费销售额
               this.seriesData = [{name: '总销售额',type: 'line',
                  data: this.cha_totalMoney,
                  /* areaStyle: {
                     normal: {
                        color: '#091e3b' //改变区域颜色
                     }
                  },
                  itemStyle: {
                     normal: {
                        // color: '#091e3b', //改变折线点的颜色
                        lineStyle: {
                           color: '#016ae4' //改变折线颜色
                        }
                     }
                  },*/
               },{ name: '房费销售额',type: 'line',
                  data: this.cha_hotelMoney,
               },{ name: '非房费销售额',type: 'line',
                  data: this.cha_otherMoney}]

               let legendData = []
               this.seriesData.forEach(item=>{ legendData.push(item.name)})
               // 折线图
               Discount_chart = {
                  title: {
                     text: '收入',
                     // subtext: '订单数',
                  },
                  tooltip: {
                     trigger:'item',
                     show:true
                  },
                  grid: {
                     left: '0%',
                     right: '4%',
                     bottom: '3%',
                     containLabel: true
                  },
                  data: ['订单数1'],
                  legend: {
                     data: legendData
                  },
                  xAxis: {
                     type: 'category',
                     data: this.timeData
                  },
                  yAxis: {
                     type: 'value',
                  },
                  series:this.seriesData
               };
               Discount_chart && myChart.setOption(Discount_chart);
               window.onresize=()=>{
                  myChart.resize()
               }
            }
         })
      },
      //营业时间发生变化时触发
      handleTime(val){
        if( this.checkboxGroup === "customDate"){
           this.radioChange()
        }
      },
      // 搜索
      queryEvent(){
         if(!this.pickerTime){
            this.$message('请选择运营时间范围');
         }else if(this.checkboxGroup === 'customDate'){
            this.getholtelAccount()
            this.chartEvent()
         }
      },
      // 重置
      resetEvent(){
         if(this.checkboxGroup === 'customDate') this.pickerTime = []
      },
      //导出事件
      exportEvent(){
         if(!this.pickerTime){
            this.$message('请选择营业时间范围再导出xlsx表格');
         }else if(this.checkboxGroup === 'today'){
            this.$message('导出失败，无法导出本日数据');
         }else if(this.checkboxGroup === "week" && this.getWeekList[0] == this.today){
            this.$message('导出失败，本日为周一，无法导出本日数据');
         }else if(this.checkboxGroup === "month" &&  this.getMonth()[0] == this.today ){
            this.$message('导出失败，本日为本月1号，无法导出本日数据');
         }else{
            const url = reconciliation.getAmountExcel
            let param = {
               beginTime:this.pickerTime[0],
               endTime:this.pickerTime[1],
               hotelId:this.hotelInfo.id,
            }
            this.$axios.get(url,param,{},'blob').then(res => {
               exportFile(res,'营销概况')
            })
         }
      },
      //获取本月开始日期+结束日期
      getMonth(){
         let date = new Date();
         let nowYear = date.getFullYear();
         let nowMonth = date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
         let monthDaynum = new Date(nowYear, nowMonth, 0).getDate() //本月天数
         let arr = [nowYear + '-' + nowMonth + '-' + "01",nowYear + '-' + nowMonth + '-' + monthDaynum]
         return arr;
      },
   },
}
</script>
<style scoped lang="scss">
.AccountTable{
   width: 100%;
   .head-tag-box{
      list-style: none;
      display: flex;
      //display: inline-block;
      width: 32%;
      height: 120px;
      margin: 0px 80px 40px 0px;
      padding: 0px 40px;
      color: #FFFFFF;
      border-radius: 8px;
      line-height: 120px;
      font-family: Microsoft YaHei;
      li:first-child{
         float: left;
         width: 50%;
         font-size: 18px;
         font-weight: 400;
      }
      li:last-child{
         float: left;
         width: 50%;
         font-size: 28px;
         font-weight: bold;
         overflow:hidden;white-space:nowrap;text-overflow:ellipsis;
      }
   }
   .head-bule{
      background: url("../../../../assets/account-bg-bule.png") 0 0 no-repeat;
      background-size: 100% 100%;
   }
   .head-gree{
      background: url("../../../../assets/account-bg-gree.png") 0 0 no-repeat;
      background-size: 100% 100%;
   }
   .head-red{
      background: url("../../../../assets/account-bg-red.png") 0 0 no-repeat;
      background-size: 100% 100%;
   }
   .group-box{
      ::v-deep .el-radio-button__inner{
         background: transparent;
      }
   }
}
</style>
